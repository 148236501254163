<template>
  <div class="forwardToWechat">
    <pageHead v-if="showPageHead">
      <van-icon name="ellipsis" slot="right" @click="handleForward"/>
    </pageHead>
    <div class="forwardContent">
      <div class="head">
        <user-info :user-name="data.userName" :time="data.createTime"  :admin ="data.superAdmin"  :iconUrl="isAnonymous?'':data.userAvatar "/>
        <div class="head_right">
<!--          <p class="tag">-->
<!--            <span class="label">转发量</span>-->
<!--            <span>{{data.forwardCount}}</span>-->
<!--          </p>-->
        </div>
      </div>
      <topic-content
        isDetail
        contentIndent
        :title="data.activityName"
        :content="data.content"/>
    </div>
  </div>
</template>

<script>
import pageHead from '@/components/pageHead'
import userInfo from '@/components/topicItem/components/userInfo'
import topicContent from '@/components/topicItem/components/content'
import {requestMobile} from "@/utils/shanbusdk";
import {addPosterForwardConut, getPosterDetail} from "@/api/postermanagement";

export default {
  components: {
    pageHead,
    userInfo,
    topicContent
  },
  data () {
    return {
      data: {},
      showPageHead: false
    }
  },
  computed:{
    isAnonymous() {
      const {data: {anonymous}} = this
      return parseInt(anonymous) === 1
    },
  },
  mounted() {
    this.id = this.$route.query.id
    getPosterDetail(this.$route.query.id).then(res => {
      this.data = res

    })
    this.showPageHead = sessionStorage.getItem("isShanbu")
  },
  methods: {
    handleForward() {
      const {id, activityName, content} = this.data
      // 如果是闪布进行转发
      if(sessionStorage.getItem("isShanbu")){
        requestMobile("share/onMenuShare",{
              "title" : "分享标题标题", // 分享目录的title，默认 '分享'
              "type" : "10027", // 10023：链接消息（圈子），10027：链接消息（通用）
              "content":  {
                "title": activityName, // 分享标题
                "link": window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                "desc" : content,// 分享描述
                "imgUrl": require('../../../../../public/images/common/hx.png'), // 分享图标
                "icon": require('../../../../../public/images/common/hx.png'), // 图标
                "iconTitle": "不氪套社区",//
              },
              "items": [ 1,2,3,4 ] // 1.thunbu 2.weChat 3.朋友圈 4.钉钉 ,5.微博 6...... ,
            }
        ).then(() => {
          addPosterForwardConut(id)
        })
      }else{
        this.$notify({message: '请在闪布内转发！', type: 'danger'});
      }
    }
  }
}
</script>

<style lang='scss' scoped>
.forwardToWechat {
  background-color: #fff;
  height: 100%;
  overflow-y: auto;

  .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    &_right {
      display: flex;

      .tag {
        &:not(:last-child) {
          margin-right: 5px;
        }

        color: #F23030;
        background-color: #FFEEEB;
        font-size: 12px;
        font-weight: bold;
        height: 22px;
        padding: 0 10px;
        line-height: 22px;
        border-radius: 11px;
        flex-shrink: 0;
        text-align: center;

        .label {
          margin-right: 5px;
        }
      }
    }
  }

  .forwardContent {
    padding: 20px;
  }
}
</style>
